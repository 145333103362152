import axios from "./axiosClient";

export function fetchCertificates(formData) {
  return axios
    .get("certificates", { params: formData })
    .then((response) => response.data);
}

export function fetchCertificateById(id) {
  return axios.get(`certificates/${id}`).then((response) => response.data);
}

export function fetchAllSubjectsByStudent(id) {

  return axios.get(`students/${id}/subjects/all`).then((response) => response.data);
}

export function insertCertificate(data) {
  return axios.post("certificates", data).then((response) => response.data);
}

export function updateCertificate(id, data) {
  return axios.put(`certificates/${id}`, data).then((response) => response.data);
}

export function changeCertificateStatus(id) {
    return axios.patch(`certificates/${id}/status`);
  }

export function deleteCertificate(id) {
  return axios.delete(`certificates/${id}`).then((response) => response.data);
}

export function downloadCertificate(id) {
  return axios
    .get(`certificates/${id}/generate-certificate`, { responseType: "blob" })
    .then((response) => response.data);
}
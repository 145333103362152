import axiosClient from "./axiosClient";

export function fetchHRDepartments() {
  return axiosClient.get("departments").then((response) => response.data);
}

export function fetchHREducationDegrees() {
  return axiosClient.get("education-degrees").then((response) => response.data);
}

export function fetchHREmploymentTypes() {
  return axiosClient.get("employment-types").then((response) => response.data);
}

export function fetchHRMaritalStatuses() {
  return axiosClient.get("marital-statuses").then((response) => response.data);
}

export function fetchHRStaffTypes() {
  return axiosClient.get("staff-types").then((response) => response.data);
}

export function fetchHRWorkScheduleTypes() {
  return axiosClient
    .get("work-schedule-types")
    .then((response) => response.data);
}

export function fetchHRWorkplaceTypes() {
  return axiosClient.get("work-place-types").then((response) => response.data);
}

export function fetchHREmployees(params) {
  return axiosClient
    .get("employees", { params: params })
    .then((response) => response.data);
}

export function insertHREmployee(data) {
  return axiosClient.post("employees", data).then((response) => response.data);
}

export function fetchHRGenders() {
  return axiosClient.get("genders").then((response) => response.data);
}

export function fetchHREmployee(id) {
  return axiosClient.get(`employees/${id}`).then((response) => response.data);
}

export function updateHREmployee(data) {
  return axiosClient
    .put(`employees/${data.id}`, data)
    .then((response) => response.data);
}

export function fetchHREmployeeContracts(id) {
  return axiosClient
    .get(`employees/${id}/contracts`)
    .then((response) => response.data);
}

export function fetchHRContractTypes() {
  return axiosClient.get("contract-types").then((response) => response.data);
}

export function insertHREmployeeContract(id, data) {
  return axiosClient
    .post(`employees/${id}/contracts`, data)
    .then((response) => response.data);
}

export function updateHREmployeeContract(employeeId, id, data) {
  return axiosClient
    .put(`employees/${employeeId}/contracts/${id}`, data)
    .then((response) => response.data);
}

export function getHREmployeeContract(employeeId, id) {
  return axiosClient
    .get(`employees/${employeeId}/contracts/${id}`)
    .then((response) => response.data);
}

export function deleteHREmployeeContract(employeeId, id) {
  return axiosClient
    .delete(`employees/${employeeId}/contracts/${id}`)
    .then((response) => response.data);
}

export function fetchHROrderTypes() {
  return axiosClient.get("order-types").then((response) => response.data);
}

export function fetchHREmployeeOrders(id) {
  return axiosClient
    .get(`employees/${id}/orders`)
    .then((response) => response.data);
}

export function insertHREmployeeOrder(id, data) {
  return axiosClient
    .post(`employees/${id}/orders`, data)
    .then((response) => response.data);
}

export function updateHREmployeeOrder(employeeId, id, data) {
  return axiosClient
    .put(`employees/${employeeId}/orders/${id}`, data)
    .then((response) => response.data);
}

export function getHREmployeeOrder(employeeId, id) {
  return axiosClient
    .get(`employees/${employeeId}/orders/${id}`)
    .then((response) => response.data);
}

export function deleteHREmployeeOrder(employeeId, id) {
  return axiosClient
    .delete(`employees/${employeeId}/orders/${id}`)
    .then((response) => response.data);
}

export function fetchHREmployeeIternshipAndSalary(id) {
  return axiosClient
    .get(`employees/${id}/work-experience-and-salary`)
    .then((response) => response.data);
}

export function updateHREmployeeIternshipAndSalary(data) {
  return axiosClient
    .put(`employees/${data.employeeId}/work-experience`, data)
    .then((response) => response.data);
}

export function fetchHREmployeeVacationBalances(id) {
  return axiosClient
    .get(`/employees/${id}/vacation-balances`)
    .then((response) => response.data);
}

export function fetchHREmployeeVacationBalance(employeeId, id) {
  return axiosClient
    .get(`/employees/${employeeId}/vacation-balances/${id}`)
    .then((response) => response.data);
}

export function insertHREmployeeVacationBalance(id, data) {
  return axiosClient
    .post(`employees/${id}/vacation-balances`, data)
    .then((response) => response.data);
}

export function updateHREmployeeVacationBalance(data) {
  return axiosClient
    .put(`employees/${data.employeeId}/vacation-balances/${data.id}`, data)
    .then((response) => response.data);
}

export function deleteHREmployeeVacationBalance(employeeId, id) {
  return axiosClient
    .delete(`employees/${employeeId}/vacation-balances/${id}`)
    .then((response) => response.data);
}

export function toggleEmployeeArchiving(id) {
  return axiosClient
    .patch(`employees/${id}/toggle-archive`)
    .then((response) => response.data);
}

export function viewHREmployee(id) {
  return axiosClient
    .get(`employees/${id}/view`)
    .then((response) => response.data);
}

export function fetchHREmployeeDocuments(employeeId) {
  return axiosClient
    .get(`employees/${employeeId}/documents`)
    .then((response) => response.data);
}

export function insertHREmployeeDocument(employeeId, data) {
  return axiosClient
    .post(`employees/${employeeId}/documents`, data)
    .then((response) => response.data);
}

export function updateHREmployeeDocument(employeeId, documentId, data) {
  return axiosClient
    .put(`employees/${employeeId}/documents/${documentId}`, data)
    .then((response) => response.data);
}

export function fetchHRDocumentTypes() {
  return axiosClient.get("document-types").then((response) => response.data);
}

export function downloadHRDocument(employeeId, encodedHash) {
  return axiosClient
    .get(`employees/${employeeId}/documents/${encodedHash}/download`, {
      responseType: "blob",
    })
    .then((response) => response.data);
}

export function previewHRDocument(employeeId, encodedHash) {
  return axiosClient
    .get(`employees/${employeeId}/documents/${encodedHash}/preview`, {
      responseType: "blob",
    })
    .then((response) => response.data);
}

export function fetchHRProfilePhoto(employeeId, width, height) {
  return axiosClient
    .get(`employees/${employeeId}/profile-photo/${width}/${height}`)
    .then((response) => response.data);
}

export function deleteHREmployeeDocument(employeeId, documentId) {
  return axiosClient
    .delete(`employees/${employeeId}/documents/${documentId}`)
    .then((response) => response.data);
}

export function fetchNonSelectedUsers(input) {
  return axiosClient
    .get(`employees/non-users/${input}`)
    .then((response) => response.data);
}

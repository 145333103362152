import axios from './axiosClient'

export function fetchRoles({
  name,
  description,
  offset,
  limit,
  sortedBy,
  sortedDesc
}) {
  return axios
    .get('roles', {
      params: {
        name,
        description,
        offset,
        limit,
        sortedBy,
        sortedDesc
      }
    })
    .then(response => response.data)
}

export function fetchRoleById(id) {
  return axios.get(`roles/${id}`).then(response => response.data)
}

export function fetchAllRoles() {
  return axios.get('roles/all').then(response => response.data)
}

export function insertRole({ roleName, roleKey, isStatic, description }) {
  return axios
    .post('roles', { roleName, roleKey, isStatic, description })
    .then(response => response.data)
}

export function updateRole(id, { roleName, roleKey, isStatic, description }) {
  return axios
    .put(`roles/${id}`, { roleName, roleKey, isStatic, description })
    .then(response => response.data)
}

export function deleteRole(id) {
  return axios.delete(`roles/${id}`).then(response => response.data)
}

import axios from "./axiosClient";

export function logIn({ username, password }) {
  return axios
    .post("sign-in", {
      userName: username,
      password,
    })
    .then((response) => response.data);
}

export function refreshToken() {
  var data = {
    refreshToken: localStorage.getItem("refresh_token"),
  };

  return axios
    .post("refresh-sign-in", data)
    .then((response) => response.data);
}

import axios from "./axiosClient";

export function fetchSizes(formData) {
  return axios
    .get("sizes", { params: formData })
    .then((response) => response.data);
}

export function fetchSizeById(id) {
  return axios.get(`sizes/${id}`).then((response) => response.data);
}

export function fetchAllSizes() {
  return axios.get("sizes/all").then((response) => response.data);
}

export function insertSize(data) {
  return axios.post("sizes", data).then((response) => response.data);
}

export function updateSize(id, data) {
  return axios.put(`sizes/${id}`, data).then((response) => response.data);
}

export function deleteSize(id) {
  return axios.delete(`sizes/${id}`).then((response) => response.data);
}

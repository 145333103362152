import axiosClient from "./axiosClient";

export function fetchAllStudents() {
  return axiosClient.get("students/all").then((response) => response.data);
}

export function fetchStudentTypes() {
  return axiosClient.get("student-types").then((response) => response.data);
}

export function fetchLessonScheduleTypes() {
  return axiosClient
    .get("lesson-schedule-types")
    .then((response) => response.data);
}

export function fetchStudents(params) {
  return axiosClient
    .get("students", { params: params })
    .then((response) => response.data);
}

export function insertStudent(data) {
  return axiosClient.post("students", data).then((response) => response.data);
}

export function fetchStudent(id) {
  return axiosClient.get(`students/${id}`).then((response) => response.data);
}

export function updateStudent(data) {
  return axiosClient
    .put(`students/${data.id}`, data)
    .then((response) => response.data);
}

export function fetchStudentContracts(id) {
  return axiosClient
    .get(`students/${id}/contracts`)
    .then((response) => response.data);
}

export function fetchStudentContractTypes() {
  return axiosClient.get("student-contract-types").then((response) => response.data);
}

export function insertStudentContract(id, data) {
    console.log(id,data)
  return axiosClient
    .post(`students/${id}/contracts`, data)
    .then((response) => response.data);
}

export function updateStudentContract(studentId, id, data) {
  return axiosClient
    .put(`students/${studentId}/contracts/${id}`, data)
    .then((response) => response.data);
}

export function getStudentContract(employeeId, id) {
  return axiosClient
    .get(`students/${employeeId}/contracts/${id}`)
    .then((response) => response.data);
}

export function deleteStudentContract(employeeId, id) {
  return axiosClient
    .delete(`students/${employeeId}/contracts/${id}`)
    .then((response) => response.data);
}


export function toggleStudentArchiving(id) {
  return axiosClient
    .patch(`students/${id}/toggle-archive`)
    .then((response) => response.data);
}

export function viewStudent(id) {
  return axiosClient
    .get(`students/${id}/view`)
    .then((response) => response.data);
}

export function fetchStudentDocuments(employeeId) {
  return axiosClient
    .get(`students/${employeeId}/documents`)
    .then((response) => response.data);
}

export function insertStudentDocument(employeeId, data) {
  return axiosClient
    .post(`students/${employeeId}/documents`, data)
    .then((response) => response.data);
}

export function updateStudentDocument(employeeId, documentId, data) {
  return axiosClient
    .put(`students/${employeeId}/documents/${documentId}`, data)
    .then((response) => response.data);
}

export function fetchStudentDocumentTypes() {
  return axiosClient.get("student-document-types").then((response) => response.data);
}

export function downloadStudentDocument(employeeId, encodedHash) {
  return axiosClient
    .get(`students/${employeeId}/documents/${encodedHash}/download`, {
      responseType: "blob",
    })
    .then((response) => response.data);
}

export function previewStudentDocument(employeeId, encodedHash) {
  return axiosClient
    .get(`students/${employeeId}/documents/${encodedHash}/preview`, {
      responseType: "blob",
    })
    .then((response) => response.data);
}

export function fetchStudentProfilePhoto(employeeId, width, height) {
  return axiosClient
    .get(`students/${employeeId}/profile-photo/${width}/${height}`)
    .then((response) => response.data);
}

export function deleteStudentDocument(employeeId, documentId) {
  return axiosClient
    .delete(`students/${employeeId}/documents/${documentId}`)
    .then((response) => response.data);
}

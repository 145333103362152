import axios from "axios";
import API from "@/services";
import store from "@/store";

const axiosClient = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL,
  timeout: 10000,
});

axiosClient.interceptors.request.use((config) => {
  const token = localStorage.getItem(process.env.VUE_APP_ACCESS_TOKEN_KEY);
  if (token && config.url != "refresh-sign-in") {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

axiosClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      !window.refreshTokenInProgress &&
      error.request.status == 401 &&
      error.config.url !== "refresh-sign-in" &&
      error.config.url !== "login"
    ) {
      var accessToken = "";
      var refreshToken = "";
      var response = "";

      await API.refreshToken().then(async (response) => {
        accessToken = response.accessToken;
        refreshToken = response.refreshToken;
        store.dispatch("updateTokens", { accessToken, refreshToken });
        response = response.response;
      });

      const config = error.config;
      if (!response || response == "") {
        throw error;
      }

      config.headers.Authorization = `Bearer ${accessToken}`;

      return new Promise((resolve, reject) => {
        axios
          .request(config)
          .then((response) => resolve(response))
          .catch((e) => reject(e));
      });
    } else {
      throw error;
    }
  }
);

export default axiosClient;

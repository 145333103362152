import * as roles from "./roles";
import * as users from "./users";
import * as auth from "./auth";
import * as employees from "./employees";
import * as sizes from "./sizes";
import * as products from "./products";
import * as productOrders from "./productOrders";
import * as students from "./students"
import * as subjects from "./subjects"
import * as certificates from "./certificate"

export default {
  ...roles,
  ...users,
  ...auth,
  ...employees,
  ...sizes,
  ...products,
  ...productOrders,
  ...students,
  ...subjects,
  ...certificates
};

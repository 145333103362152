import axios from "./axiosClient";

export function fetchProductOrders(formData) {
  return axios
    .get("product-orders", { params: formData })
    .then((response) => response.data);
}

export function fetchProductOrderById(id) {
  return axios.get(`product-orders/${id}`).then((response) => response.data);
}

export function insertProductOrder(data) {
  return axios.post("product-orders", data).then((response) => response.data);
}

export function insertRefundProductOrder(data) {
  return axios.post("product-orders/refund", data).then((response) => response.data);
}

export function updateProductOrder(id, data) {
  return axios
    .put(`product-orders/${id}`, data)
    .then((response) => response.data);
}

export function deleteProductOrder(id) {
  return axios.delete(`product-orders/${id}`).then((response) => response.data);
}

export function deleteRefundProductOrder(id) {
  return axios.delete(`product-orders/${id}/refund`).then((response) => response.data);
}
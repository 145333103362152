import axios from "./axiosClient";

export function fetchSubjects(formData) {
  return axios
    .get("subjects", { params: formData })
    .then((response) => response.data);
}

export function fetchSubjectById(id) {
  return axios.get(`subjects/${id}`).then((response) => response.data);
}

export function fetchAllSubjects() {
  return axios.get("subjects/all").then((response) => response.data);
}

export function fetchAllSubjectTypes() {
    return axios.get("subject-types/all").then((response) => response.data);
  }

export function insertSubject(data) {
  return axios.post("subjects", data).then((response) => response.data);
}

export function updateSubject(id, data) {
  return axios.put(`subjects/${id}`, data).then((response) => response.data);
}

export function changeSubjectStatus(id) {
    return axios.patch(`subjects/${id}/status`);
  }

export function deleteSubject(id) {
  return axios.delete(`subjects/${id}`).then((response) => response.data);
}

import axios from "./axiosClient";

export function fetchUsers({
  firstName,
  lastName,
  userName,
  emailAddress,
  positionId,
  roleId,
  phoneNumber,
  offset,
  limit,
  sortedBy,
  sortedDesc,
}) {
  return axios
    .get("users", {
      params: {
        firstName,
        lastName,
        userName,
        emailAddress,
        positionId,
        roleId,
        phoneNumber,
        offset,
        limit,
        sortedBy,
        sortedDesc,
      },
    })
    .then((response) => response.data);
}

export function fetchAllUsers() {
  return axios.get("users/all").then((response) => response.data);
}

export function fetchUserById(id) {
  return axios.get(`users/${id}`).then((response) => response.data);
}

export function insertUser({
  userName,
  firstName,
  lastName,
  middleName,
  emailAddress,
  phoneNumber,
  positionId,
  password,
  passwordRepeat,
  roleIds,
  employeeId,
}) {
  return axios
    .post("users", {
      userName,
      firstName,
      lastName,
      middleName,
      emailAddress,
      phoneNumber,
      positionId,
      password,
      passwordRepeat,
      roleIds,
      employeeId,
    })
    .then((response) => response.data);
}

export function updateUser({
  id,
  userName,
  firstName,
  lastName,
  middleName,
  emailAddress,
  phoneNumber,
  positionId,
  password,
  passwordRepeat,
  roleIds,
}) {
  return axios
    .put(`users/${id}`, {
      userName,
      firstName,
      lastName,
      middleName,
      emailAddress,
      phoneNumber,
      positionId,
      password,
      passwordRepeat,
      roleIds,
    })
    .then((response) => response.data);
}

export function changeUserStatus(id) {
  return axios.patch(`users/${id}/status`);
}

export function updatePassword(id, { password, passwordRepeat }) {
  return axios.patch(`users/${id}/password`, {
    password,
    passwordRepeat,
  });
}

export function resetPassword({ oldPassword, password, passwordRepeat }) {
  return axios.patch(`users/reset-password`, {
    oldPassword,
    password,
    passwordRepeat,
  });
}

export function checkUsernameUniqueness(username, userId) {
  return axios
    .get(`users/check-username-uniqueness`, {
      params: { username, userId },
    })
    .then((response) => response.data);
}

export function fetchAllPositions() {
  return axios.get("positions/all").then((response) => response.data);
}

import axios from "./axiosClient";

export function fetchProducts(formData) {
  return axios
    .get("products", { params: formData })
    .then((response) => response.data);
}

export function fetchProductById(id) {
  return axios.get(`products/${id}`).then((response) => response.data);
}

export function fetchAllProducts() {
  return axios.get("products/all").then((response) => response.data);
}

export function fetchAllColors() {
  return axios.get("colors/all").then((response) => response.data);
}

export function fetchAllUnits() {
  return axios.get("units/all").then((response) => response.data);
}

export function insertProduct(data) {
  return axios.post("products", data).then((response) => response.data);
}

export function updateProduct(id, data) {
  return axios.put(`products/${id}`, data).then((response) => response.data);
}

export function changeProductStatus(id) {
  return axios.patch(`products/${id}/status`);
}

export function deleteProduct(id) {
  return axios.delete(`products/${id}`).then((response) => response.data);
}
